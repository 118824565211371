import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { DataTable } from "primereact/datatable";

const ModalChangeDriver = (props) => {
  const [driverSelected, setDriverSelected] = useState(null);
  const [vehiclesDriverAvailable, setVehiclesDriverAvailable] = useState(props.vehiclesDriverAvailable || []);
  
 const onConfirm = () => {
    if (!driverSelected) return; 
    props.onConfirm({vehiclePlate:driverSelected, routeId:props.vehicleChangeDriver.routeId});
    setDriverSelected(null);
  };

  useEffect(() => {
    const { vehicle, routeId, routeColor } = props.vehicleChangeDriver;
    let plate = "";
    let label = "";
    if (vehicle && vehicle.vehicleAvailable) {
      plate = vehicle.vehicleAvailable.plate; 
    }
    label = vehicle.code;
    const newList = []; 
    vehiclesDriverAvailable.forEach((veh, index) => {
      if (label === veh.vehicleType.label) {
        if (veh.plate === plate) { 
          setDriverSelected(vehiclesDriverAvailable[index]);
        }
        newList.push(veh);
      }
    });
    setVehiclesDriverAvailable(newList);
  }, [props.vehicleChangeDriver, props.vehiclesDriverAvailable]);


  const footer = (
    <div>
      <ButtonDialog
        onClick={() => {
          onConfirm();
          setDriverSelected(null);
        }}
        button={{
          title: "Confirmar",
          disabled: !driverSelected || vehiclesDriverAvailable.length == 0,
          className: "lognex-btn-highlighted",
          iconClassName: "pi pi-check",
        }}
        dialog={{
          header: "Confirmação",
          modal: false,
          message: "Deseja realmente alterar a veículo/placa na rota?",
        }}
      ></ButtonDialog>

      <button title="Cancelar" className="lognex-btn-danger" onClick={props.onHide}>
        <i className="pi pi-times"></i>
      </button>
    </div>
  );

  const templateEmUso = (rowData) => {
     if (rowData.routeId && rowData.routeId > 0) {
      return "Em uso";
    }else {
      return "Disponível";
    }      
  };


  let columns = [<Column key="selectorColumnDrive" selectionMode="single" style={{ width: "3em" }} />];
  columns = [
    ...columns,
    ...props.columns.map((col) => {
      if (col.field === "plate") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }
      if (col.field === "available") {
        return <Column {...col} key={col.field} body={templateEmUso} />;
      }
      if (col.field === "routeId") {
        return <Column {...col} key={col.field} style={{ width: "100px" }}  />;
      }
      if (col.field === "routeId") {
        return <Column {...col} key={col.field}  />;
      }
      //mostrar na coluna routeId a cor da rota e o id da rota "Definido na Rota ID Cor"
      if (col.field === "routeColor") {
        return (
          <Column
            Column {...col} key={col.field} style={{ width: "35px" }} body={(rowData) => {
              if (rowData.routeId) {
                return (
                  <div   style={{backgroundColor: rowData.routeColor, 
                    width: "20px",
                    height: "20px",
                    border: "0.5px solid #ccc",
                  }} />
                );
              } else {
                return <span></span>;
              }
            }
            } />
        );
      }
      return <Column {...col} key={col.field} />;
    }),
  ];


  return (
    <Dialog
      className="content"
      header={"Alterar veículo da rota, com veiculos disponíveis"}
      footer={footer}
      visible={props.visible}
      modal={true}
      onHide={() => {
        setDriverSelected(null);
        props.onHide();
      }}
      style={props.style || { width: "60vw" }}
    >
      <div style={{ minHeight: "300px" }}>
        <h3>Selecione uma das placas abaixo!</h3>

        <DataTable
          value={vehiclesDriverAvailable}
          emptyMessage="Não foram encontrados registros"
          onRowClick={(e) => {
            setDriverSelected(e.data);
          }}
          scrollable={true}
          scrollHeight={"300px"}
          onSelectionChange={(e) => setDriverSelected(e.value)}
          selection={driverSelected}
        >
          {columns}
        </DataTable>
      </div>
    </Dialog>
  );
};
export default ModalChangeDriver;